import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Landing = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulLanding {
        edges {
          node {
            saveTheDate {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
  `)

  return (
    <div className="landing">
      {data.allContentfulLanding.edges.map(edge => {
        return (
          <>
            <img src={edge.node.saveTheDate.file.url} alt={edge.node.title} />
            <div>{edge.node.title}</div>
          </>
        )
      })}
    </div>
  )
}

export default Landing
